import React from "react";
import Footer from "src/components/Footer_en";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/en/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/en/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/en/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/ultra_light/">Ultra Light</a>
                      </li>
                      <li>
                        <a href="/en/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/en/our-products/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/en/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/en/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/en/our-products/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/en/our-products/all_products/">
                          All products
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/en/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/en/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/instantglow/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/instantglow/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/instantglow/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/instantglow/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/instantglow/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/instantglow/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2017-pizbuin_1680x405px_Instant-Glow.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/Instant_Glow_960x405px_2016_NEW.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN INSTANT GLOW<sup>®</sup>
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Instantly beautifying protection</h3>
                      <p>
                        Now your skin can have that beautiful summer shimmer
                        instantly and all summer long – all without compromising
                        on protection. <br /> The lightly tinted PIZ BUIN
                        INSTANT GLOW<sup>®</sup> contains delicate,
                        light-reflecting pearls that illuminate your skin with a
                        beautiful subtle golden shimmer while PIZ BUIN
                        <sup>®</sup>'s advanced UVA/UVB filter system gives your
                        skin immediate and effective sun protection.
                      </p>
                      <p className="ccm-note footNote">
                        Not a self tan product!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="skin-illuminating-sun-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/f401f4d5d55aa06431dc6ed4c3017817_f99.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>Skin illuminating sun spray</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          The fast absorbing non greasy, non sticky, spray
                          hydrates the skin. And, due to our innovative
                          multi-position "upside down" packaging, PIZ BUIN
                          INSTANT GLOW<sup>®</sup> gives you a convenient
                          application, every time. Doesn't contain self tan!{" "}
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreens. Keep babies and children out of direct
                          sunlight. For use on face, spray into hands and apply.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                BETACAROTENE
                                <div className="textBlock bottom">
                                  <p>
                                    Beta carotene is a colored orange pigment
                                    abundant in plant and fruit combined. It
                                    helps to give the skin an instant natural
                                    skin color and a healthy look. It can be
                                    washed of easily with soap. Not a self tan!
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/fa5146555d82b0de6956d3ffaf04bf1c_f91.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                CARAMEL
                                <div className="textBlock bottom">
                                  <p>
                                    Caramel is a brown color dye that instantly
                                    gives the skin a natural color and a healthy
                                    look. It can be washed of easily with soap.
                                    Not a self tan!
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4bca608325f7d1f697e7783639540cfe_f92.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                LIGHT REFLECTING PEARLS
                                <div className="textBlock bottom">
                                  <p>
                                    Our delicate light reflecting pearls feature
                                    an advanced coating technology with a golden
                                    reflection color. Thanks to their
                                    multi-layer pigment technology the light is
                                    reflected on each layer of the pigment
                                    resulting in a more intense reflection and a
                                    higher purity of the color. The end result
                                    is a stunning subtle golden color which
                                    provides outstanding skin coverage and an
                                    immediate beautiful glow enhancing the
                                    natural look and beauty of your skin. Not a
                                    self tan!
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/197557fee29adfef39ee77a61900c7bb_f90.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="skin-illuminating-sun-lotion" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/1668375df47225efbcfeb9eeeebea57c_f404.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>Skin Illuminating Sun Lotion</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          This fast absorbing, non greasy, non sticky lotion
                          moisturizes the skin. Doesn't contain self tan! PIZ
                          BUIN INSTANT GLOW<sup>®</sup> Skin Illuminating Sun
                          Lotion is also sweat and water resistant.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreens. Keep babies and children out of direct
                          sunlight. For use on face, spray into hands and apply.
                        </p>
                        <p> </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                BETACAROTENE
                                <div className="textBlock bottom">
                                  <p>
                                    Beta carotene is a colored orange pigment
                                    abundant in plant and fruit combined. It
                                    helps to give the skin an instant natural
                                    skin color and a healthy look. It can be
                                    washed of easily with soap. Not a self tan!
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/fa5146555d82b0de6956d3ffaf04bf1c_f91.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                CARAMEL
                                <div className="textBlock bottom">
                                  <p>
                                    Caramel is a brown color dye that instantly
                                    gives the skin a natural color and a healthy
                                    look. It can be washed of easily with soap.
                                    Not a self tan!
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4bca608325f7d1f697e7783639540cfe_f92.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                LIGHT REFLECTING PEARLS
                                <div className="textBlock bottom">
                                  <p>
                                    Our delicate light reflecting pearls feature
                                    an advanced coating technology with a golden
                                    reflection color. Thanks to their
                                    multi-layer pigment technology the light is
                                    reflected on each layer of the pigment
                                    resulting in a more intense reflection and a
                                    higher purity of the color. The end result
                                    is a stunning subtle golden color which
                                    provides outstanding skin coverage and an
                                    immediate beautiful glow enhancing the
                                    natural look and beauty of your skin. Not a
                                    self tan!
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/197557fee29adfef39ee77a61900c7bb_f90.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/en/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/ultra_light/" target="_self">
                      Ultra Light
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/protect_cool/" target="_self">
                      Protect &amp; Cool
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/en/our-products/instantglow/"
                      target="_self"
                    >
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/wetskin/" target="_self">
                      Wet skin
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/aftersun/" target="_self">
                      After sun
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/all_products/" target="_self">
                      All products
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Read More');\n} else {\nbox.slideDown();\n$(this).text('Read Less');\n}\n});\n});\n"
          }}
        />
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
